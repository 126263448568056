var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "availabilityContainer" },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "mb-0": "" } },
        [
          _c("v-flex", { staticClass: "flex-starving" }, [
            _c("span", { staticClass: "elementSubTitle" }, [
              _vm._v("Beschikbaarheid JZ")
            ])
          ])
        ],
        1
      ),
      _vm.currentStartDate
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("ExpertAvailability", {
                    attrs: {
                      startDate: _vm.currentStartDate,
                      isPlanner: _vm.isPlanner,
                      isImmaterial: true
                    },
                    on: {
                      previousweek: _vm.goToPreviousWeek,
                      nextweek: _vm.goToNextWeek,
                      goToDate: _vm.goToDate
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }